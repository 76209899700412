import { createApp, ref } from "vue";
import App from "./App.vue";
import router from "./router";
import { IonicVue, toastController } from "@ionic/vue";
import { Cropper } from "vue-advanced-cropper";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";
import "vue-advanced-cropper/dist/style.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

/* Theme variables */
import "./theme/variables.css";
import { UserRepository } from "./repositories/user.repository";
import { CityRepository } from "./repositories/city.repository";
import {
  CategoryRepoKey,
  CityRepoKey,
  MarketRepoKey,
  UploadRepoKey,
  UserRepoKey,
} from "./symbols/repository.symbols";
import {
  CommonRepoKey,
  SelectedCityKey,
  ToastKey,
} from "./symbols/common.symbols";
import { CategoryRepository } from "./repositories/category.repository";
import { MarketRepository } from "./repositories/market.repository";
import { UploadRepository } from "./repositories/upload.repository";
import { CommonRepository } from "./repositories/common.repository";

const openToast = {
  show: async (message: string) => {
    const toast = await toastController.create({
      message: message,
      duration: 2000,
    });
    return toast.present();
  },
};

const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .component("cropper", Cropper)
  .component("v-select", vSelect)
  .provide(CommonRepoKey, CommonRepository)
  .provide(UserRepoKey, UserRepository)
  .provide(CityRepoKey, CityRepository)
  .provide(CategoryRepoKey, CategoryRepository)
  .provide(UploadRepoKey, UploadRepository)
  .provide(MarketRepoKey, MarketRepository)
  .provide(ToastKey, openToast)
  .provide(SelectedCityKey, ref(""));

router.isReady().then(() => {
  app.mount("#app");
});
