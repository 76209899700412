/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { AxiosError } from "axios";
import { http } from "@/common/httpClient";
import { UploadImageViewModel } from "@/view-models/upload-image.view-model";

export class UploadRepository {
  static async uploadImage(model: UploadImageViewModel): Promise<any> {
    try {
      const formData = new FormData();
      formData.append("files", model.photo.blob!, model.photo.fileName);
      formData.append("ref", model.ref);
      formData.append("refId", model.refId);
      formData.append("field", model.field);
      const resp = await http(`/upload`, "post", undefined, formData);
      return resp;
    } catch (error) {
      const err = error as AxiosError;
      return err;
    }
  }
}
