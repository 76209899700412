import { MarketRepository } from "@/repositories/market.repository";
import { AuthUserViewModel } from "@/view-models/auth-user.view-model";
import { Ref, ref } from "vue";

export const useAuthUser = () => {
  const authUser: Ref<AuthUserViewModel | null> = ref({
    jwt: "",
    user: {
      id: 0,
      username: "",
      email: "",
      provider: "",
      confirmed: false,
      blocked: false,
      createdAt: new Date(),
      updatedAt: new Date(),
      marketCount: null,
    },
  });
  const fetchUser = async () => {
    const user = JSON.parse(localStorage.getItem("user") ?? "{}");
    const resultUser = user;
    const count = await MarketRepository.getMarketByUserCount();
    resultUser.user.marketCount = count.data;
    authUser.value = resultUser;
  };

  return {
    fetchUser,
    authUser,
  };
};
