/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { AxiosError } from "axios";
import { http } from "@/common/httpClient";

export class CategoryRepository {
  static async getCategories(params: Record<string, string>): Promise<any> {
    try {
      const resp = await http(`/categories`, "get", undefined, null, params);
      return resp;
    } catch (error) {
      const err = error as AxiosError;
      return err;
    }
  }
}
