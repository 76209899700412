/* eslint-disable @typescript-eslint/no-non-null-assertion */
import axios from "axios";

export class CommonRepository {
  static getAddressByCep(cep: string) {
    return axios.get(`https://brasilapi.com.br/api/cep/v2/${cep}`);
  }

  static getAdressByGeolocation(latitude: string, longitude: string) {
    return axios.get(
      `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}`
    );
  }
}
