/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { AxiosError } from "axios";
import { http } from "@/common/httpClient";

export class CityRepository {
  static async getCities(): Promise<any> {
    try {
      const resp = await http(`/cities`, "get", undefined);
      return resp;
    } catch (error) {
      const err = error as AxiosError;
      return err;
    }
  }
}
