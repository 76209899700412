/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { AxiosError } from "axios";
import { http } from "@/common/httpClient";
import { MarketViewModel } from "@/view-models/market.view-model";

export class MarketRepository {
  static async getMarketsByName(name: string): Promise<any> {
    try {
      const resp = await http(
        `/markets?filters[name][$containsi]=${name}`,
        "get",
        undefined
      );
      return resp;
    } catch (error) {
      const err = error as AxiosError;
      return err;
    }
  }

  static async getHighlightMarkets(city: string): Promise<any> {
    try {
      const resp = await http(
        `/markets?populate=cover,image, cities&filters[highlight][$eq]=true${
          city ? `&filters[cities][id][$eq]=${city}` : ""
        }`,
        "get",
        undefined
      );
      return resp;
    } catch (error) {
      const err = error as AxiosError;
      return err;
    }
  }

  static async getMarketBySlug(slug: string): Promise<any> {
    try {
      const resp = await http(
        `/markets?populate=cover,image,categories&filters[slug][$eq]=${slug}`,
        "get",
        undefined
      );
      return resp;
    } catch (error) {
      const err = error as AxiosError;
      return err;
    }
  }

  static async getMarketByUser(): Promise<any> {
    try {
      const resp = await http(
        `/market-by-user?populate=cover,image,categories`,
        "get",
        undefined
      );
      return resp;
    } catch (error) {
      const err = error as AxiosError;
      return err;
    }
  }

  static async getMarketByUserCount(): Promise<any> {
    try {
      const resp = await http(`/market-by-user-count`, "get", undefined);
      return resp;
    } catch (error) {
      const err = error as AxiosError;
      return err;
    }
  }
  static async addMarket(model: MarketViewModel): Promise<any> {
    try {
      const resp = await http(`/markets`, "post", undefined, model);
      return resp;
    } catch (error) {
      const err = error as AxiosError;
      return err;
    }
  }
}
