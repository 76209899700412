import { CommonRepository } from "@/repositories/common.repository";
import { AuthUserViewModel } from "@/view-models/auth-user.view-model";
import { InjectionKey, Ref } from "vue";

export const CommonRepoKey: InjectionKey<typeof CommonRepository> =
  Symbol("CommonRepository");

export const ToastKey: InjectionKey<{
  show: (message: string) => Promise<void>;
}> = Symbol("Toast");

export const AuthUserKey: InjectionKey<Ref<AuthUserViewModel>> =
  Symbol("AuthUser");

export const SelectedCityKey: InjectionKey<Ref<string | null>> =
  Symbol("SelectedCity");
