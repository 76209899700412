import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import jwt_decode from "jwt-decode";

const page = (path: string, name: string) => {
  return import(`@/views${path}/${name}/${name}.page.vue`);
};

const layout = (path: string, name: string) => {
  return import(`@/views${path}/${name}/${name}.layout.vue`);
};

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/tabs/home",
  },
  {
    path: "/login",
    name: "Login",
    component: () => page("/auth", "login"),
  },
  {
    path: "/register",
    name: "Register",
    component: () => page("/auth", "register"),
  },
  {
    path: "/forgot",
    name: "Forgot",
    component: () => page("/auth", "forgot"),
  },
  {
    path: "/email-confirmed",
    name: "EmailConfirmed",
    component: () => page("/auth", "email-confirmed"),
  },
  {
    path: "/awaiting-confirmation",
    name: "AwaitingConfirmation",
    component: () => page("/auth", "awaiting-confirmation"),
  },
  {
    path: "/terms",
    name: "Terms",
    component: () => page("", "terms"),
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () => page("", "privacy-policy"),
  },

  {
    path: "/",
    component: () => layout("", "default"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "company-details/:slug",
        component: () => page("", "company-details"),
      },
    ],
  },
  {
    path: "/tabs/",
    component: () => layout("", "tabs"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        redirect: "/tabs/home",
      },
      {
        path: "home",
        component: () => page("", "home"),
      },
      {
        path: "promote",
        component: () => page("", "promote"),
      },
      {
        path: "promoted-companies",
        component: () => page("", "promoted-companies"),
      },
      {
        path: "search",
        component: () => page("", "search"),
      },
      {
        path: "account",
        component: () => page("", "account"),
      },
      {
        path: "description",
        component: () => page("", "description"),
      },
      {
        path: "companies",
        component: () => page("", "companies"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const isInvalid = () => {
  const user = JSON.parse(localStorage.getItem("user") ?? "{}");
  if (!user.jwt) return true;
  const userToken: any = jwt_decode((user ?? { jwt: "" }).jwt);
  const expires = userToken.exp;
  const timeNow = new Date().getTime() / 1000;
  const invalid = expires < timeNow;
  if (invalid) {
    localStorage.removeItem("user");
    return true;
  } else return false;
};

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth) && isInvalid()) {
    return next({
      name: "Login",
    });
  } else {
    return next();
  }
});

export default router;
