import axios, { AxiosRequestHeaders, Method } from "axios";
import { Constants } from "./constants";

export function http(
  url: string,
  method: Method,
  headers?: AxiosRequestHeaders,
  data: any = {},
  params?: Record<string, string>
) {
  const baseURL = Constants.apiUrl;
  const user = JSON.parse(localStorage.getItem("user") ?? "{}");
  if ((user.jwt && !headers) || (headers && !headers.Authorization)) {
    headers = { Authorization: `Bearer ${user.jwt}` };
  }
  return axios({
    method,
    url: `${baseURL}${url}`,
    data,
    headers,
    params,
  });
}
