/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { AxiosError } from "axios";
import { http } from "@/common/httpClient";
import { LoginViewModel } from "@/view-models/login.view-model";
import { RegisterViewModel } from "@/view-models/register.view-model";

export class UserRepository {
  static async login(model: LoginViewModel): Promise<any> {
    try {
      const resp = await http(`/auth/local`, "post", undefined, model);
      return resp;
    } catch (error) {
      const err = error as AxiosError;
      return err;
    }
  }

  static async register(model: RegisterViewModel): Promise<any> {
    try {
      const resp = await http(`/auth/local/register`, "post", undefined, model);
      return resp;
    } catch (error) {
      const err = error as AxiosError;
      return err;
    }
  }

  static async getUsersMe(): Promise<any> {
    try {
      const resp = await http(`/users/me`, "get", undefined);
      return resp;
    } catch (error) {
      const err = error as AxiosError;
      return err;
    }
  }

  static async sendForgotEmail(email: string): Promise<any> {
    try {
      const resp = await http(`/auth/forgot-password`, "post", undefined, {
        email,
      });
      return resp;
    } catch (error) {
      const err = error as AxiosError;
      return err;
    }
  }
}
