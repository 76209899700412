import { CategoryRepository } from "@/repositories/category.repository";
import { CityRepository } from "@/repositories/city.repository";
import { MarketRepository } from "@/repositories/market.repository";
import { UploadRepository } from "@/repositories/upload.repository";
import { UserRepository } from "@/repositories/user.repository";
import { InjectionKey } from "vue";

export const UserRepoKey: InjectionKey<typeof UserRepository> =
  Symbol("UserRepository");
export const CityRepoKey: InjectionKey<typeof CityRepository> =
  Symbol("CityRepository");
export const CategoryRepoKey: InjectionKey<typeof CategoryRepository> =
  Symbol("CategoryRepository");
export const MarketRepoKey: InjectionKey<typeof MarketRepository> =
  Symbol("MarketRepository");
export const UploadRepoKey: InjectionKey<typeof UploadRepository> =
  Symbol("UploadRepository");
